/* eslint-disable react/prop-types */
import React from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, GeoJSON } from "react-leaflet";
import { useCustomEventListener } from "react-custom-events";
import country from "../Core/shapes/benin.json";
import { emitCustomEvent } from "react-custom-events";

// To be imported from backend
const layers = [];

export default function RegionSelector(props) {
  const { regions, interventionSet, filterMethod } = props;

  const commonStyle = {
    color: "black",
    weight: 1.3,
  };
  const interventionStyle = {
    ...commonStyle,
    fillColor: "yellow",
    fillOpacity: 1,
  };
  const deselectedStyle = {
    ...commonStyle,
    fillColor: "gray",
    fillOpacity: 0.3,
  };
  const neutralStyle = {
    ...commonStyle,
    fillColor: "white",
    fillOpacity: 1,
  };

  const setInitialStyle = (layer) => {
    if (interventionSet.indexOf(layer.feature.properties.name) !== -1) {
      layer.setStyle(interventionStyle);
    } else {
      layer.setStyle(neutralStyle);
    }
  };

  useCustomEventListener("re-init", () => {
    layers.forEach((layer) => {
      setInitialStyle(layer);
    });
  });

  const onEachFeature = (feature, layer) => {
    if (interventionSet.indexOf(feature.properties.name) !== -1) {
      layer.on({
        click: () => {
          layer.setStyle(interventionStyle);
          layers.forEach((element) => {
            if (element.feature.properties.name !== feature.properties.name) {
              if (element.options.fillColor === interventionStyle.fillColor) {
                element.setStyle(deselectedStyle);
              }
            }
          });
          console.log(filterMethod);
          emitCustomEvent("filter", feature.properties.name);
        },
      });
    }
    setInitialStyle(layer);
    layers.push(layer);
  };

  const bounds = L.geoJSON(country).getBounds();
  return (
    <MapContainer
      bounds={bounds}
      zoomControl={false}
      dragging={false}
      zoom={12}
      doubleClickZoom={false}
      scrollWheelZoom={false}
    >
      <GeoJSON data={regions} onEachFeature={onEachFeature} />
    </MapContainer>
  );
}
