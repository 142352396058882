import React, { useState } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  BarController,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { RefreshIcon } from "@heroicons/react/solid";
import { IconButton } from "@mui/material";
import { emitCustomEvent } from "react-custom-events";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import RegionSelector from "./RegionSelector";
import "./style.css";
import {
  ChartDataTypeInterface as DivisionTypeInterface,
  TERRITORY_DIVISION_TYPES,
  DASHBOARD_URL,
} from "../../constants";
import ChartDataTypeController from "./ChartDataTypeController";
import { Navigate, useLocation, useOutletContext } from "react-router-dom";
import ChartRenderer from "./ChartsRenderer";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  BarController,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels,
);

type ContextType = { filterParams: any; selectedDivTypeName: any };

export default function Dashboard() {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */
  const currentLocation = useLocation().pathname;

  const [selectedDivisionType, setSelectedDivisionType] = useState(
    TERRITORY_DIVISION_TYPES[0],
  );
  const [selectedDivTypeName, setDivisionTypeName] = useState(selectedDivisionType.id);
  const [filterParams, setFilterParams] = useState<any>([]);

  const changeDivisionType = (newDivisionType: DivisionTypeInterface) => {
    setSelectedDivisionType(newDivisionType);
    setDivisionTypeName(newDivisionType.id);
    setFilterParams([]);
  };

  const filterByDivision = (divisionName: string) => {
    const params = new URLSearchParams([[selectedDivisionType.id, divisionName]]);
    setFilterParams(params);
  };

  return (
    <>
      <div className="App px-4 h-full">
        <div className="lg:flex justify-between h-full">
          <div className="w-1/3 2xl:w-1/4 md:flex md:flex-col md:justify-center shadow-2xl drop-shadow-2xl rounded-2xl">
            <div className="p-0 sm:min-h-5/6 md:h-full">
              {/* <div className="w-1/2 absolute right-2 top-2 z-[1000]">
                <ChartDataTypeController
                  selectedChartDataType={selectedDivisionType}
                  onDataTypeChanged={changeDivisionType}
                />
              </div> */}
              {/* Define a region selector per division type. */}
              {TERRITORY_DIVISION_TYPES.map(
                (regionsData) =>
                  selectedDivTypeName === regionsData.id && (
                    <RegionSelector
                      key={regionsData.id}
                      filterMethod={filterByDivision}
                      regions={regionsData.geojson}
                      interventionSet={regionsData.interventionSet}
                    />
                  ),
              )}
              <div className="absolute top-1 z-40">
                <IconButton
                  onClick={() => {
                    emitCustomEvent("re-init");
                    setFilterParams([]);
                  }}
                >
                  <RefreshIcon className="w-10" />
                </IconButton>
              </div>
            </div>
          </div>
          {/* <Tabs className="mt-6 graph-wrapper md:h-full md:overflow-auto"> */}
          <ChartRenderer
            filterParams={filterParams}
            selectedDivTypeName={selectedDivTypeName}
          />
        </div>
      </div>
    </>
  );
}
