import React from "react";

function NotFoundView() {
  return (
    <div>
      <span className="text-black">PAGE INTROUVABLE</span>
    </div>
  );
}

export default NotFoundView;
