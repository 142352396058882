/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  LineController,
  Title,
  Tooltip,
  Legend,
  ChartData,
  PieController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import { useCustomEventListener } from "react-custom-events";
import transformApiResponse, {
  ParamsGetDataFunction,
} from "../../api/responseTransformer";
import CustomError from "../../models/CustomErrorModel";
import Alert from "../Core/Alert";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  DoughnutController,
  PieController,
  LineController,
  Title,
  Tooltip,
  Legend,
);

const generateBarChatOption = (chartProps: any, chartData: any) => ({
  responsive: true,
  scales: chartProps.stacked
    ? {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      }
    : {},
  plugins: {
    legend: {
      display: chartData.datasets.length > 1,
      position: "top" as const,
      labels: {
        usePointStyle: true,
      },
    },
    title: {
      display: true,
      text: chartProps.primaryChartName,
    },
    datalabels: {
      display: "auto" as const,
      color: "black",
      anchor: "center" as const,
      font: { size: 15, weight: "bolder" as const },
      // align: "end" as constx
    },
  },
});

// const LOADING_BARCHAT_DATA = {
//   labels: [],
//   datasets: [
//     {
//       label: "En cours de chargement",
//       data: [],
//       backgroundColor: "rgba(255, 99, 132, 0.5)",
//     },
//   ]
// }

type BarchartProps = {
  chartProps: any;
  initialDivisionType: string;
  filterParams: any;
  charType?: any;
};

interface OptionnalParam {
  level?: number;
  label?: string;
}

/**
 * Identify next level (axis) to display on chart and returns its metaData
 * This level can be "communes" or initially sent through props one (Departments or Poles)
 */
const getChartMetaData = (element: any, optionnalParam: OptionnalParam) => {
  let { label } = optionnalParam;
  if (!label) {
    label = "commune";
  }
  const returnMe = element.levels.find((elmt: any) => elmt.metaData.label === label);
  return returnMe.metaData;
};

// const getLabel = (propsParams: any, initialDivisionType: any) => {
//   if (propsParams instanceof URLSearchParams && (propsParams.has("departement") || propsParams.has("pole"))) return "commune"
//   return initialDivisionType
// }

export default function BarChart(props: BarchartProps) {
  const { filterParams: propsParams } = props;

  const { chartProps, initialDivisionType } = props;
  const [chartData, setChartData] = useState<ChartData<"bar", number[], unknown>>();
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);

  const label =
    propsParams instanceof URLSearchParams ? "commune" : initialDivisionType;
  const [chartMetaData, setChartMetaData] = useState(
    getChartMetaData(chartProps, { label }),
  );
  const [uRLSearchParams, setURLSearchParams] = useState<URLSearchParams | undefined>(
    propsParams,
  );
  const [params, setParams] = useState<
    ParamsGetDataFunction<ChartData<"bar", number[], unknown>>
  >({
    endPoint: chartMetaData.endPoint,
    isArrayTransformer: true,
    transformer: chartMetaData.transformer,
  });
  const chartRef = useRef<ChartJS>(null);

  var { charType } = props;
  charType = charType || "bar";

  useEffect(() => {
    /**
     * Change chart meta data in order to switch from departement axis
     * to commune axis
     * Note that it happens after uRLSearchParams is not null
     * i.e. after we attempt to filter data (after a click on a region)
     * Fixme: After the first change of uRLSearchParams, this set
     *        chartMetaData to the exact same value causing no effect on it
     */
    if (uRLSearchParams) {
      const label =
        uRLSearchParams instanceof URLSearchParams ? "commune" : initialDivisionType;
      setChartMetaData(getChartMetaData(chartProps, { label }));
    }
  }, [uRLSearchParams]);

  useEffect(() => {
    /**
     * Define next api call params
     * Again there is a condition to make sure the params change happens only
     * when label (which defines axis) is not the same as initial.
     * This will be certainly caused after the previous useEffect has changed
     * chartProps once at least.
     */
    if (chartMetaData.label !== initialDivisionType) {
      setParams({
        endPoint: chartMetaData.endPoint,
        isArrayTransformer: true,
        params: uRLSearchParams,
        transformer: chartMetaData.transformer,
      });
    }
  }, [
    chartMetaData,
    // We add uRLSearchParams here because of the previous useEffect fixme
    uRLSearchParams,
  ]);

  useEffect(() => {
    /**
     * Execute an api call and update chart each time params change
     */
    setApiCalling(true);
    setHasError(false);
    (async () => {
      const chartParams = chartProps.params || {};

      Object.entries(chartParams).map((v: any) => {
        const [name, value] = v;
        if (
          name &&
          (!(params.params instanceof URLSearchParams) ||
            params.params.get(name) != value)
        ) {
          // Todo: We find out the first graph params.params is shared by others
          //       Investigate on it
          let apiCallParams = params.params || new URLSearchParams();
          apiCallParams.set(name, value);
          params.params = apiCallParams;
        }
      });

      const requestResponse = await transformApiResponse<
        ChartData<"bar", number[], unknown>
      >(params);
      if (requestResponse instanceof CustomError) {
        setApiCalling(false);
        setHasError(true);
      } else {
        requestResponse.datasets[0].label =
          chartProps.legendStart + requestResponse.datasets[0].label;
        if (charType === "doughnut") {
          const backgroundColors = [
            "#e30067",
            "#e19a17",
            "#66c843",
            "#46b994",
            "#5448a0",
            "#88008b",
          ];
          requestResponse.datasets[0].backgroundColor = backgroundColors;
        }
        setChartData(requestResponse as unknown as ChartData<"bar", number[], unknown>);
        setApiCalling(false);
        setHasError(false);
      }
    })();
  }, [params]);

  useCustomEventListener("re-init", () => {
    const initialChartMetaData = getChartMetaData(chartProps, {
      label: initialDivisionType,
    });
    setParams({
      endPoint: initialChartMetaData.endPoint,
      isArrayTransformer: true,
      transformer: initialChartMetaData.transformer,
    });
    setChartMetaData(initialChartMetaData);
  });

  useCustomEventListener("filter", (name: string) => {
    const initialChartMetaData = getChartMetaData(chartProps, {
      label: "commune",
    });
    setParams({
      endPoint: initialChartMetaData.endPoint,
      isArrayTransformer: true,
      transformer: initialChartMetaData.transformer,
      params: new URLSearchParams([["departement", name]]),
    });
  });

  return (
    <>
      {!apiCalling && hasError && (
        <div className="animate-pulse">
          <Alert description={"Une erreur s'est produite"} />
        </div>
      )}
      {!apiCalling && !hasError && (
        <Chart
          type={charType}
          ref={chartRef}
          options={{
            plugins: {
              legend: {
                display: charType != "bar",
              },
              title: {
                display: true,
                text: chartProps.primaryChartName,
              },
              datalabels: {
                color: "#FFFFFF",
                font: {
                  weight: "bold",
                  size: 16,
                },
              },
            },
          }}
          // options={generateBarChatOption(chartProps, chartData)}
          data={chartData as ChartData<"bar", number[], unknown>}
          height="500px"
          width="500px"
        />
      )}
    </>
  );
}
