import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// Conponents
import LoginPage from "./views/LoginView";
import AboutPage from "./views/AboutView";
import ProfileView from "./components/Profile/ProfileView";
import NotFoundView from "./components/NotFound/NotFoundView";
import DataTableView from "./components/DataTable/DataTableView";
import Dashboard from "./components/Dashboard/Dashboard";
import NotFoundOutletView from "./components/NotFound/NotFoundOutletView";
import "./i18n";
import Mapping from "./components/Mapping/Mapping";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate to="app/dashboard" />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="*" element={<NotFoundView />} />
      <Route path="app" element={<App />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<ProfileView />} />
        <Route path="mapping" element={<Mapping />} />
        <Route path="datatable" element={<DataTableView />} />
      </Route>
      <Route path="about" element={<AboutPage />} />
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
