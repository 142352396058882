/* eslint-disable no-unused-vars */
/* eslint-disable max-len */

import DataModel from "../models/AbstractDataModel";
import CustomError from "../models/CustomErrorModel";
import { getFromApiFunction } from "./apiFunctions";

// This interface is used to pack transformApiResponse function's parameters
export interface ParamsGetDataFunction<P> {
  endPoint: string /* Since we use only one function to get all data, we need to provide the end point when calling on the function responsible of request */;
  params?: URLSearchParams /* Contains Request parameters */;
  isArrayTransformer?: boolean /* Determine if the transformer function transform an Array or a single data */;
  // eslint-disable-next-line no-unused-vars
  transformer: (
    jsonValue: any,
  ) => P /* The function responsible of Data transformation, usually transform data from json to Model */;
}

// Transform Data into data usable by the client app
export default async function transformApiResponse<D>(
  paramsValue: ParamsGetDataFunction<D>,
): Promise<any> {
  const { endPoint, params, isArrayTransformer, transformer } = paramsValue;
  return getFromApiFunction(endPoint, params)
    .then((response: any) => {
      const dataResults = response.data;
      if (Array.isArray(dataResults) && !isArrayTransformer) {
        const tempData: D[] = [];
        dataResults.map((result: any) => tempData.push(transformer(result)));
        return tempData;
      }
      return transformer([...dataResults]);
    })
    .catch((error) => {
      const returnMe = new CustomError(error);
      return returnMe;
    });
}
