/* eslint-disable react/prop-types */
import React from "react";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

function Table({ tableData, columns, fixedHeight = true }) {
  const muiCache = createCache({
    key: "mui",
    prepend: true,
  });

  const scrollBarClasses =
    "scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-slate-50 overflow-auto";

  const getMuiTheme = () =>
    createTheme({
      palette: {
        primary: {
          main: "#788c82",
        },
        secondary: {
          main: "#eb70d3",
        },
        common: {
          black: "#788c82",
        },
      },
      shape: {
        borderRadius: 20,
      },
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: "#AAF",
          },
          responsiveScroll: {
            backgroundColor: "red",
          },
          paper: {
            boxShadow: "none",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: "#FFF",
          },
        },
        MuiToolbar: {
          root: {
            backgroundColor: "#f00",
          },
        },
        MuiTableCell: {
          head: {
            backgroundColor: "purple",
            width: "200px",
          },
        },
        MUIDataTableSelectCell: {
          headerCell: {
            backgroundColor: "blue",
          },
        },
      },
    });

  const options = {
    // filterType: "checkbox",
    selectableRows: false,
    pagination: false,
    tableBodyHeight: fixedHeight ? "calc(100vh - 270px)" : "",
    filterType: "dropdown",
    responsive: "standard",
    fixedHeader: true,
  };

  return (
    <div>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            class={scrollBarClasses}
            title=""
            data={tableData ? tableData.map((item) => [...item]) : ["Aucune donnée"]}
            columns={columns}
            options={options}
          />
        </ThemeProvider>
      </CacheProvider>
    </div>
  );
}

export default Table;
