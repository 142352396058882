import { useEffect, useState } from "react";

export function useLocalStorageState(key: string, defaultValue: any) {
  const [state, setState] = useState(() => {
    let value;
    try {
      const keyValue = localStorage.getItem(key);
      if (keyValue) {
        value = JSON.parse(keyValue);
        if (value === undefined || value === null) {
          value = defaultValue;
        }
      } else {
        value = defaultValue;
      }
    } catch (error) {
      value = defaultValue;
    }
    return value;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
