export default function linkClass(currentLocation: string, linkPath: string) {
  const inactiveClasses =
    "font-bold my-4 block rounded-full text-white flex flex-col md:flex-row items-center text-tiny link hover:bg-stone-50 hover:text-black";
  // "font-medium my-4 text-white align-middle flex flex-col md:flex-row items-center text-tiny hover:bg-blue-100 hover:rounded-full hover:text-black link";
  const activeClasses =
    "font-bold my-4 onprimary-background block rounded-full text-white flex flex-col md:flex-row items-center text-tiny link";

  let stringClasses = inactiveClasses;
  if (currentLocation === linkPath) {
    stringClasses = activeClasses;
  }
  return stringClasses;
}
