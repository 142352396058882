interface CustomErrorConstructorInterface {
  originType?: string;
  code?: string;
  message: string;
}

export default class CustomError {
  originType?: string;

  code?: string;

  message: string;

  constructor({ originType, code, message }: CustomErrorConstructorInterface) {
    this.originType = originType;
    this.code = code;
    this.message = message;
  }
}
