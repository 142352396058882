import React, { useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Filler,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  BarController,
  CategoryScale,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Tab, Tabs, Box } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./style.css";
import { getFromApiFunction } from "../../api/apiFunctions";
import BarChart from "./BarChart";
import { Bar, Pie, PolarArea } from "react-chartjs-2";
import {
  CATEGORIES_CHART,
  CVA_CHARTS,
  MALNUTRITION_CHART,
  STAKE_HOLDER_CHART,
  departementsIntervention,
} from "../../constants";
import { randomInt } from "crypto";
// import { useFilters } from "./Dashboard";

ChartJS.register(
  LinearScale,
  RadialLinearScale,
  CategoryScale,
  BarElement,
  ArcElement,
  PointElement,
  BarController,
  LineElement,
  RadarController,
  PolarAreaController,
  Filler,
  Tooltip,
  Legend,
  ChartDataLabels,
);

const scrollBarClasses =
  // "scrollbar-thin scrollbar-thumb-slate-500 scrollbar-track-slate-50 overflow-auto";
  "scrollbar scrollbar-thumb-red-500 scrollbar-track-gray-100 flex-grow";

export default function ChartRenderer(props: any) {
  /**
   * In the following base code:
   * - divisionType is a specific type of territory division (departments or poles)
   * - division is a specific division of a given type (Ex: Alibori for departments and PDA1 for poles)
   */

  const { filterParams, selectedDivTypeName } = props;

  const [value, setValue] = React.useState(0);

  const [indics, setIndics]: any = React.useState([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let theme = createTheme({
    palette: {
      primary: {
        main: "#788c82",
      },
      secondary: {
        main: "#eb70d3",
      },
    },
  });

  // useEffect(() => {
  //   getFromApiFunction("indicators/").then((response) => {
  //     setIndics(response.data.results);
  //   });
  // }, []);

  const chartColors = {
    red: "rgb(255, 99, 132)",
    orange: "rgb(255, 159, 64)",
    yellow: "rgb(255, 205, 86)",
    green: "rgb(75, 192, 192)",
    blue: "rgb(54, 162, 235)",
    purple: "rgb(153, 102, 255)",
    grey: "rgb(201, 203, 207)",
  };

  const getOptions = (title: String) => ({
    plugins: {
      title: {
        display: true,
        text: title,
      },
    },
  });

  const pieData = {
    labels: departementsIntervention,
    datasets: [
      {
        label: "Nombre de collaborateurs",
        data: departementsIntervention.map((dep) => Math.ceil(Math.random() * 30)),
        // you can set indiviual colors for each bar
        backgroundColor: Object.values(chartColors),
        borderWidth: 1,
      },
    ],
  };

  const barData = {
    labels: departementsIntervention,
    datasets: [
      {
        label: "Nombre de spéculations",
        data: departementsIntervention.map((dep) => Math.ceil(Math.random() * 30)),
        borderWidth: 1,
        backgroundColor: chartColors.orange,
      },
    ],
  };

  const barData2 = {
    labels: departementsIntervention,
    datasets: [
      {
        label: "Acteurs concernés",
        data: departementsIntervention.map((dep) => Math.ceil(Math.random() * 30)),
        borderWidth: 1,
        backgroundColor: chartColors.blue,
      },
    ],
  };

  const pieData2 = {
    labels: departementsIntervention,
    datasets: [
      {
        label: "Acteurs concernés",
        data: departementsIntervention.map((dep) => Math.ceil(Math.random() * 30)),
        // you can set indiviual colors for each bar
        backgroundColor: Object.values(chartColors),
        borderWidth: 1,
      },
    ],
  };

  const width = "relative w-[450px] mb-8 m-auto 2xl:mx-[10px]";

  return (
    <div className={scrollBarClasses}>
      <div className="md:grid 2xl:grid-cols-2 2xl:grid-rows-2 w-fit m-auto rounded-md">
        <div className={width}>
          <BarChart
            chartProps={CATEGORIES_CHART}
            initialDivisionType={selectedDivTypeName}
            filterParams={filterParams}
            charType="pie"
          />
          {/* <Pie
            data={pieData}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Yes",
                },
              },
            }}
          /> */}
        </div>
        <div className={width}>
          <BarChart
            chartProps={STAKE_HOLDER_CHART}
            initialDivisionType={selectedDivTypeName}
            filterParams={filterParams}
          />

          {/* <Bar
            className="vertical-center"
            data={barData}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Yes",
                },
              },
            }}
          /> */}
        </div>
        <div className={width}>
          <BarChart
            chartProps={MALNUTRITION_CHART}
            initialDivisionType={selectedDivTypeName}
            filterParams={filterParams}
            // charType="polarArea"
          />
        </div>
        <div className={width}>
          <BarChart
            chartProps={CVA_CHARTS}
            initialDivisionType={selectedDivTypeName}
            filterParams={filterParams}
            // charType="polarArea"
          />
        </div>
      </div>
    </div>
  );
}
