/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import "./App.css";
import NavBar from "./components/SideBar/NavBar";
import SideBar from "./components/SideBar/SideBar";
import { useLocalStorageState } from "./components/utils";
import { APP_URL } from "./constants";

function App() {
  const [open, setOpen] = useLocalStorageState("sideBarOpen", true);

  const toggleOpenSideBar = () => {
    setOpen(!open);
  };

  const currentLocation = useLocation().pathname;

  return (
    <div className="flex flex-col md:flex-row outlet-background h-screen overflow-hidden">
      <div className="flex-none">
        <SideBar open={open} toggleOpenSideBar={toggleOpenSideBar} />
      </div>
      <div className="h-screen outlet-background flex flex-col flex-grow">
        <div className="outlet-background">
          <NavBar />
        </div>
        <div className="mt-10 outlet-background main-outlet-container">
          <Outlet />
        </div>
      </div>
      {currentLocation === "/app/" && <Navigate to="dashboard/a" replace />}
    </div>
  );
}

export default App;
