/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
// eslint-disable-next-line import/prefer-default-export
// export const API_URL = "https://a71b14a2c907.eu.ngrok.io/"
import { HomeIcon, GlobeAltIcon, TableIcon } from "@heroicons/react/solid";
import L from "leaflet";
import departements from "./components/Core/shapes/departement.json";
import benin from "./components/Core/shapes/benin.json";

export const { REACT_APP_API_URL: API_URL } = process.env;

export const DEFAULT_PAGE_SIZE = 0;
export const APP_URL = "/app";
export const DASHBOARD_URL = `${APP_URL}/dashboard`;
export const HOMEPAGE_URL = `${APP_URL}/homepage`;
export const CARTO_URL = `${APP_URL}/cartotheque`;
export const MAPPING_URL = `${APP_URL}/mapping`;
export const REPORT_URL = `${APP_URL}/report`;
export const DATA_URL = `${APP_URL}/datatable`;
export const CONTRIBUTOR_URL = `${APP_URL}/contributor`;
export const PROFILE_URL = `${APP_URL}/profile`;
export const SETTINGS = `${APP_URL}/settings`;

const chartColors = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

const DEFAULT_COLOR = chartColors.orange;

export const TITLECLASS =
  "capitalize tracking-wide text-blue-400 font-extrabold text-3xl indent-1.5";

const chartDataFromJson = (
  dataToGet: string,
  jsonData?: any,
  attributes?: any,
  backgroundColors: string[] = [DEFAULT_COLOR],
  chartLabels?: string[],
) => {
  const labels: string[] = [];
  let yAxisdata: number[] = [];

  const datasets: any = attributes?.map((attribute: any, idx: any) => {
    let dataset: any = {
      label: chartLabels ? chartLabels[idx] : dataToGet,
      data: [],
      backgroundColor: backgroundColors,
      // borderColor: backgroundColors,
    };
    if (typeof attribute !== "string") {
      const [att, type] = attribute;
      dataset.type = type;
      if (type == "line") {
        dataset = {
          ...dataset,
          borderColor: "rgba(0, 0, 0, 0)",
          pointBorderColor: backgroundColors[idx],
          pointStyle: "line",
          datalabels: { display: false },
          pointRadius: 52,
          pointBorderWidth: 5,
          pointHoverRadius: 52,
          pointHoverBorderWidth: 5,
        };
      }
    }
    return dataset;
  });

  if (jsonData && attributes) {
    jsonData.forEach((element: any) => {
      labels.push(element[dataToGet]);
      attributes?.forEach((attribute: any, idx: any) => {
        if (typeof attribute !== "string") {
          const [att, type] = attribute;
          attribute = att;
        }
        const modulus = element[attribute] % 1;
        const number = modulus ? element[attribute].toFixed(2) : element[attribute];

        datasets[idx].data.push(number);
      });
    });
  }
  return {
    labels,
    datasets,
  };
};

export const SIDEBARELEMENTS = [
  {
    url: DASHBOARD_URL,
    title: "sidebar_titles.home",
    leading: () => <HomeIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <HomeIcon className="h-6 w-6 p-1 m-auto" />,
  },
  {
    url: MAPPING_URL,
    title: "sidebar_titles.mapping",
    leading: () => <GlobeAltIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <GlobeAltIcon className="h-6 w-6 p-1 m-auto" />,
  },
  {
    url: DATA_URL,
    title: "sidebar_titles.dataTable",
    leading: () => <TableIcon className="h-6 w-6 p-1" />,
    centeredLeading: () => <TableIcon className="h-6 w-6 p-1 m-auto" />,
  },
];

export interface MapViewInterface {
  id: string;
  illustration: string;
  viewName: string;
  tileLayerInfos: any;
}

const SATELITE_VIEW = {
  attribution:
    "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community",
  url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
};

const DEFAULT_VIEW = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};

export const MAP_VIEWS: MapViewInterface[] = [
  {
    id: "1",
    illustration: "/street.jpg",
    viewName: "Vue par défaut",
    tileLayerInfos: DEFAULT_VIEW,
  },
  {
    id: "2",
    illustration: "/sat.jpg",
    viewName: "Vue satelitaire",
    tileLayerInfos: SATELITE_VIEW,
  },
];

export const MAX_DATA_LEVEL: number = 1;

export interface ChartDataTypeInterface {
  id: string;
  label: string;
  geojson: object;
  // List of divisions names where the project intervene
  interventionSet: any;
}

const communesIntervention = [
  "Malanville",
  "Karimama",
  "Gogounou",
  "Banikoara",
  "Materi",
  "Toukountouna",
  "Boukoumbe",
  "Tanguieta",
  "Perere",
  "Nikki",
  "Kalale",
  "Toviklin",
  "Djakotome",
  "Klouekanme",
  "Dangbo",
  "Adjohoun",
  "Bonou",
  "Djidja",
  "Abomey",
  "Cove",
];

export const departementsIntervention = [
  "Alibori",
  "Atacora",
  "Borgou",
  "Couffo",
  "Oueme",
  "Zou",
];

/**
 * Benin territory can be divided into departements or poles.
 */
export const TERRITORY_DIVISION_TYPES: ChartDataTypeInterface[] = [
  {
    id: "departement",
    label: "Departements",
    geojson: departements,
    interventionSet: departementsIntervention,
  },
];

// Mapping
const weightStyle = { weight: 2 };
export const Layers = [
  {
    name: "Départements",
    layer: departements,
    style: weightStyle,
  },
  {
    name: "Départements d'intervention",
    layer: departements,
    style: { color: "red", ...weightStyle },
    subset: departementsIntervention,
  },
  {
    name: "Communes",
    layer: benin,
    style: { color: "green", border: "1px", weight: 0.5 },
  },
  {
    name: "Communes d'intervention",
    layer: benin,
    style: { color: "red", ...weightStyle },
    subset: communesIntervention,
  },
];
export const TABLES = [{}];

const ICON_MARKER_AMENAGEMENT = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#c30b82;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-tree'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

const ICON_MARKER_RETENU_EAU = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#ff8a80;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-water'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
});

export const ICON_MARKER_BOUTIQUE = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#9b0000;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-shop'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
  popupAnchor: [0, -30],
});

export const ICON_MARKER_MAGASIN = L.divIcon({
  className: "custom-div-icon",
  html: "<div style='background-color:#6200ea;' class='marker-pin'></div><i class='material-icons'><i class='fa-solid fa-store'></i></i>",
  iconSize: [30, 42],
  iconAnchor: [15, 42],
  popupAnchor: [0, -30],
});

export interface LayerControlInterface {
  id: string;
  endPoint: string;
  label: string;
  iconMarker: any;
}

export const LAYER_CONTROLS: LayerControlInterface[] = [
  {
    id: "1",
    endPoint: "/stakeholders/",
    label: "Parties prenantes",
    iconMarker: ICON_MARKER_MAGASIN,
  },
];

export const MAP_FEATURE_TYPES = [
  {
    id: "1",
    correspondingTypes: ["HIMO", "Aménagement en entreprise"],
  },
  {
    id: "2",
    correspondingTypes: ["MAGASINS"],
  },
  {
    id: "3",
    correspondingTypes: ["Retenue d'eau"],
  },
  {
    id: "4",
    correspondingTypes: ["Boutique"],
  },
];

export const STAKE_HOLDER_CHART = {
  primaryChartName: "Nombre de Parties prenantes",
  legendStart: "",
  levels: [
    {
      level: 0,
      metaData: {
        label: "departement",
        chartName: "départements",
        endPoint: "stakeholders/stats/by_departement/",
        transformer: (jsonData: any) =>
          chartDataFromJson("departement", jsonData, ["pk"]),
      },
    },
    {
      level: 1,
      metaData: {
        label: "commune",
        chartName: "communes",
        endPoint: "stakeholders/stats/by_commune/",
        transformer: (jsonData: any) => chartDataFromJson("commune", jsonData, ["pk"]),
      },
    },
    {
      level: 0,
      metaData: {
        label: "pole",
        chartName: "poles",
        endPoint: "stakeholders/stats/by_pole/",
        transformer: (jsonData: any) => chartDataFromJson("pole", jsonData, ["pk"]),
      },
    },
  ],
};

export const CVA_CHARTS = {
  primaryChartName: "Répartition des Chaînes de Valeur Agricoles",
  legendStart: "",
  levels: [
    {
      level: 0,
      metaData: {
        label: "departement",
        chartName: "départements",
        endPoint: "cva/stats/by_departement/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "departement",
            jsonData,
            ["value_chain__name"],
            [chartColors.blue],
          ),
      },
    },
    {
      level: 1,
      metaData: {
        label: "commune",
        chartName: "communes",
        endPoint: "cva/stats/by_commune/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "commune",
            jsonData,
            ["value_chain__name"],
            [chartColors.blue],
          ),
      },
    },
    {
      level: 0,
      metaData: {
        label: "pole",
        chartName: "poles",
        endPoint: "cva/stats/by_pole/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "name",
            jsonData,
            ["value_chain__name"],
            [chartColors.blue],
          ),
      },
    },
  ],
};

export const CATEGORIES_CHART = {
  primaryChartName: "Répartition des catégories",
  legendStart: "",
  levels: [
    {
      level: 0,
      metaData: {
        label: "departement",
        chartName: "départements",
        endPoint: "stakeholders/stats/by_category/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "category",
            jsonData,
            ["count"],
            Object.values(chartColors),
          ),
      },
    },
    {
      level: 1,
      metaData: {
        label: "commune",
        chartName: "communes",
        endPoint: "stakeholders/stats/by_category/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "category",
            jsonData,
            ["count"],
            Object.values(chartColors),
          ),
      },
    },
    {
      level: 0,
      metaData: {
        label: "pole",
        chartName: "poles",
        endPoint: "stakeholders/stats/by_pole/",
        transformer: (jsonData: any) =>
          chartDataFromJson("pole", jsonData, ["count"], Object.values(chartColors)),
      },
    },
  ],
};

export const MALNUTRITION_CHART = {
  primaryChartName: "Niveau moyen de malnutrition estimé",
  legendStart: "",
  levels: [
    {
      level: 0,
      metaData: {
        label: "departement",
        chartName: "départements",
        endPoint: "stakeholders/stats/by_departement/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "departement",
            jsonData,
            ["malnutrition_level"],
            [chartColors.green],
          ),
      },
    },
    {
      level: 1,
      metaData: {
        label: "commune",
        chartName: "communes",
        endPoint: "stakeholders/stats/by_commune/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "commune",
            jsonData,
            ["malnutrition_level"],
            [chartColors.green],
          ),
      },
    },
    {
      level: 0,
      metaData: {
        label: "pole",
        chartName: "poles",
        endPoint: "stakeholders/stats/by_pole/",
        transformer: (jsonData: any) =>
          chartDataFromJson(
            "pole",
            jsonData,
            ["malnutrition_level"],
            [chartColors.green],
          ),
      },
    },
  ],
};
