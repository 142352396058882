import axios from "./axios";

export const getFromApiFunction = async (
  endPoint: string,
  params: URLSearchParams = new URLSearchParams(),
) => axios().get(endPoint, { params });

export const createDataFunction = async (endPoint: string, payload: any) =>
  axios().post(endPoint, payload);

export const updateDataFunction = async (endPoint: string, payload: any) =>
  axios().put(endPoint, payload);

export const deleteDataFunction = async (endPoint: string, payload: any) =>
  axios().delete(endPoint, payload);
