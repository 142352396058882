import React from "react";

function AboutPage() {
  return (
    <div className="App">
      <h1>About page</h1>
    </div>
  );
}

export default AboutPage;
