import axios from "axios";
import { API_URL } from "../constants";

function getAxiosConfig() {
  // eslint-disable-next-line prefer-const
  let instanceConfig = {
    baseURL: API_URL,
    timeout: 10000,
    headers: {},
  };
  const token = localStorage.getItem("sig-papvire-access");
  if (token) {
    // instanceConfig.headers.Authorization = `Bearer ${token}`;
  }
  const axiosInstance = axios.create(instanceConfig);

  axiosInstance.interceptors.response.use(
    (config) => {
      // Intercept get `/token` response and globally set token in axios header.
      if (config?.data?.access && config?.data?.refresh) {
        localStorage.setItem("sig-papvire-access", config.data.access);
        localStorage.setItem("sig-papvire-refresh", config.data.refresh);
      }
      return config;
    },
    async (error) => {
      // "ERR_BAD_REQUEST"
      const originalConfig = error.config;
      // Intercept for token expiration error, and globally override token in
      // axios header.
      // On token expiration
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.code === "token_not_valid"
      ) {
        // If it's access token expiration
        if (
          error?.response?.data?.messages?.length &&
          error.response.data.messages[0].token_class === "AccessToken"
        ) {
          const refreshToken = localStorage.getItem("sig-papvire-refresh");
          const payload = { refresh: refreshToken };
          try {
            const response = await axiosInstance.post("/token/refresh/", payload);
            const newToken = response.data.access;
            localStorage.setItem("sig-papvire-access", newToken);
            originalConfig.headers.Authorization = `Bearer ${newToken}`;
            return axios(originalConfig);
            // eslint-disable-next-line no-shadow
          } catch (error) {
            // TODO: hanldthis
          }
          // eslint-disable-next-line brace-style
        }
        // Refresh token has expired
        else {
          window.location.href = "/";
        }
      }
      return Promise.reject(error);
    },
  );

  return axiosInstance;
}

export default getAxiosConfig;
