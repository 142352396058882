/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  GeoJSON,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Control from "react-leaflet-custom-control";
import country from "../Core/shapes/departement.json";
import bruteData from "../Core/shapes/brute_data.json";
import {
  MAP_VIEWS,
  LAYER_CONTROLS,
  ICON_MARKER_MAGASIN,
  ICON_MARKER_BOUTIQUE,
  Layers,
  API_URL,
} from "../../constants";
import ViewController from "./ViewController";
import MarkerModel from "../../models/MarkerModel";
import ZoomControlWithReset from "./ZoomWithReset";
import transformApiResponse, {
  ParamsGetDataFunction,
} from "../../api/responseTransformer";
import CustomError from "../../models/CustomErrorModel";

/* Note: DO NOT DELETE THIS COMMENT
When using local copy of leaflet css markers are not displayed properly
Following code is meant to solve the issue.
See https://github.com/PaulLeCam/react-leaflet/issues/453#issuecomment-410450387
*/
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const DEFAULT_MARKER_PER_CONTROL_LAYER = [
  {
    layerControlId: 1,
    markers: [],
  },
  {
    layerControlId: 2,
    markers: [],
  },
];

function PopupComponent(props) {
  const { marker } = props;
  const map = useMap();
  // console.log(marker)
  ["latitude", "longitude"].forEach((element) => {
    marker.label[element] = parseFloat(marker.label[element]).toFixed(4);
  });
  return (
    <Popup>
      <div
        style={{
          display: "grid",
          gap: "3px",
        }}
      >
        <div className="d-flex text-center w-full">
          <img src={marker.image} className="m-auto" alt="" width={200} height={150} />
        </div>

        <div>
          <strong>Nom de la partie prenante</strong> : {marker.label.siteName}{" "}
        </div>
        <div>
          <strong>Type d’acteur</strong> :{" "}
          {marker.label.category === "Structure étatique"
            ? "Structure étatique"
            : "Structure non étatique"}{" "}
        </div>
        <div>
          <strong>Localisation/ coordonnées géographiques </strong> :{" "}
          {marker.label.latitude}, {marker.label.longitude}{" "}
        </div>
        <div>
          <strong>Contacts (Téléphone et E-mail)</strong> : {marker.label.phone} &{" "}
          {marker.label.email}{" "}
        </div>
        <div>
          <strong>Domaine d’intervention</strong> : {marker.label.interventionFields}{" "}
        </div>
        <a
          role="button"
          onClick={() =>
            map.flyTo([marker.localisation.latitude, marker.localisation.longitude], 18)
          }
        >
          Zoomer
        </a>
      </div>
    </Popup>
  );
}

function Mapping() {
  const bounds = L.geoJSON(country).getBounds();
  const [view, setView] = useState(MAP_VIEWS[0]);
  const [tileKey, setTileKey] = useState(MAP_VIEWS[0].id);
  const [apiCalling, setApiCalling] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [markerPerControlLayer, setMarkerPerControlLayer] = useState(
    DEFAULT_MARKER_PER_CONTROL_LAYER,
  );
  const [map, setMap] = useState();
  const [markers, setMarkers] = useState([]);

  const changeView = (viewToSet) => {
    setView(viewToSet);
    setTileKey(viewToSet.id);
  };

  const didMount1 = useRef(false);
  const didMount2 = useRef(false);

  useEffect(() => {
    // console.log(didMount1)
    // if (didMount1.current) {
    setApiCalling(true);
    setHasError(false);
    const tempMarkerSet = [];
    LAYER_CONTROLS.every(async (layerControl) => {
      const params = {
        endPoint: layerControl.endPoint,
        isArrayTransformer: true,
        transformer: MarkerModel.fromJsonList,
      };
      const requestResponse = await transformApiResponse(params);
      if (requestResponse instanceof CustomError) {
        setApiCalling(false);
        setHasError(true);
        return false;
      }
      console.log(requestResponse);
      setMarkers(requestResponse);
      const tempObj = { layerControlId: layerControl.id, markers: requestResponse };
      tempMarkerSet.push(tempObj);
    });
    // console.log("TEMP MARKER SET ", tempMarkerSet);
    setMarkerPerControlLayer(tempMarkerSet);
    setApiCalling(false);
    setHasError(false);
    // } else {
    //   didMount1.current = true
    // }
  }, []);

  // useEffect(() => {
  //   const tempMarkerSet = [
  //     { layerControlId: "1", markers: [] },
  //     { layerControlId: "2", markers: [] },
  //     { layerControlId: "3", markers: [] },
  //     { layerControlId: "4", markers: [] },
  //   ];
  //   const allData = bruteData.features;
  //   try {
  //     setApiCalling(true);
  //     if (allData) {
  //       allData.forEach((feature) => {
  //         const { properties, geometry } = feature;
  //         const label = properties.Libellé;
  //         const featureType = MAP_FEATURE_TYPES.find((element) => {
  //           const { correspondingTypes } = element;
  //           const tempElmt = correspondingTypes.find(
  //             (elmt) =>
  //               elmt.toLocaleLowerCase().includes(label.toLocaleLowerCase()) ||
  //               label.toLocaleLowerCase().includes(elmt.toLocaleLowerCase()),
  //           );
  //           if (tempElmt) return true;
  //           return false;
  //         });
  //         if (featureType) {
  //           const markerSet = tempMarkerSet.find(
  //             (element) => element.layerControlId === featureType.id,
  //           );
  //           if (markerSet) {
  //             markerSet.markers.push(MarkerModel.fromJson({ properties, geometry }));
  //           }
  //         }
  //       });
  //       setMarkerPerControlLayer(tempMarkerSet);
  //     }
  //     setApiCalling(false);
  //   } catch (e) {
  //     setApiCalling(false);
  //     setHasError(true);
  //   }
  // }, []);

  return (
    <div className=" w-full h-full py-8">
      <div className="border-4 border-white w-full h-full min-h-max container mx-auto rounded-3xl">
        <MapContainer bounds={bounds} zoomControl={false}>
          <ZoomControlWithReset bounds={bounds} />
          <LayersControl position="topright">
            {Layers.map((layer) => (
              <LayersControl.Overlay key={layer.name} name={layer.name}>
                <FeatureGroup>
                  {layer.layer.features.map((feature) =>
                    // Display polygons that names are inside layer.subset
                    // if it is defined
                    !layer.subset || layer.subset.includes(feature.properties.name) ? (
                      <GeoJSON data={feature} style={layer.style || {}} />
                    ) : null,
                  )}
                </FeatureGroup>
              </LayersControl.Overlay>
            ))}

            {
              <LayersControl.Overlay key="stakeholders" name="Parties prenantes">
                <FeatureGroup>
                  {markers.map((marker, index) => {
                    return (
                      <Marker
                        position={[
                          marker.localisation.latitude,
                          marker.localisation.longitude,
                        ]}
                        icon={
                          marker.label.is_member
                            ? ICON_MARKER_MAGASIN
                            : ICON_MARKER_BOUTIQUE
                        }
                        key={index}
                      >
                        <PopupComponent marker={marker} />
                      </Marker>
                    );
                  })}
                </FeatureGroup>
              </LayersControl.Overlay>
            }

            {/* {!apiCalling &&
              !hasError &&
              LAYER_CONTROLS.map((layerControl) => {
                let markers = [];
                const layerControlKey = `${layerControl.label}-${layerControl.id}`;
                const correspondingMarkers = markerPerControlLayer.find(
                  (element) => element.layerControlId === layerControl.id,
                );
                if (correspondingMarkers) {
                  markers = correspondingMarkers.markers;
                }
                return (
                  <LayersControl.Overlay
                    key={layerControlKey}
                    name={layerControl.label}
                  >
                    <FeatureGroup>
                      {markers
                        ? markers.map((marker, index) => {
                          const markerkey = `${layerControl.label}-${index}`;
                          return (
                            <Marker
                              position={[
                                marker.localisation.latitude,
                                marker.localisation.longitude,
                              ]}
                              icon={layerControl.iconMarker}
                              key={markerkey}
                            >
                              <PopupComponent
                                marker={marker}
                                layerControl={layerControl}
                              />
                            </Marker>
                          );
                        })
                        : null}
                    </FeatureGroup>
                  </LayersControl.Overlay>
                );
              })} */}
          </LayersControl>
          <Control prepend position="topright">
            <ViewController selectedMapView={view} onMapViewChanged={changeView} />
          </Control>
          <TileLayer key={tileKey} {...view.tileLayerInfos} />
        </MapContainer>
      </div>
    </div>
  );
}

export default Mapping;
