import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiLogin } from "../../api/auth/login";
import {
  UserCircleIcon,
  LockClosedIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import Alert from "../Core/Alert";
import "./login.scss";

function LoginForm() {
  const navigate = useNavigate();
  const [errorMessage, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onEmailChanged = (event: any) => {
    setUsername(event.target.value);
  };

  const onPasswordChanged = (event: any) => {
    setPassword(event.target.value);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    apiLogin({
      username,
      password,
    })
      .then((response: any) => {
        setLoading(false);
        if (response?.response?.status === 401) {
          // setError(response.response.data.detail)
        } else {
          localStorage.setItem("username", username);
          navigate("/app/dashboard");
          const ev = new CustomEvent("authenticated", { detail: { username } });
          document.dispatchEvent(ev);
        }
      })
      .catch(() => {
        setLoading(false);
        setError("Identifiant ou mot de passe incorrect");
        // TODO: Handle error
      });
  };

  return (
    <div className="grid h-screen place-items-center primary-background root-login">
      <div className="w-3/5 flex justify-space-between container mx-auto">
        <div className="mr-2 grow flex flex-col justify-center">
          <p className="font-bold text-4xl msg">
            <span>Bienvenue</span> <br />
            <span>dans l'univers</span> <br />
            <span>PAPVIRE</span> <br />
          </p>
        </div>
      </div>
      <div className="shrink px-8 py-8 rounded-lg bg-white bg-opacity-75 text-center login-form">
        <UserCircleIcon
          className="text-slate-800 flex mx-auto mb-6"
          width="81"
          height="81"
        />
        {errorMessage && (
          <div className="mb-4 md:w-full">
            <Alert description={errorMessage} />
          </div>
        )}
        <form className="mx-auto mb-4 w-[275px]" onSubmit={onSubmit}>
          <div className="mb-4 md:w-full">
            <label htmlFor="email" className="block text-xs mb-1 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <UserCircleIcon className="icon h-6 w-6 text-gray-500" />
              </span>
              <input
                className="w-full bg-white rounded-full py-2 pl-10 pr-4 focus:outline-none"
                type="text"
                name="username"
                required
                placeholder="Nom d'utilisateur"
                value={username}
                onChange={onEmailChanged}
              />
            </label>
          </div>
          <div className="mb-6 md:w-full">
            <label htmlFor="email" className="block text-xs mb-1 relative">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon className="icon h-6 w-6 text-gray-500" />
              </span>
              <input
                className="w-full bg-white border-slate-300 rounded-full py-2 pl-10 pr-4 focus:outline-none"
                type="password"
                name="password"
                placeholder="Mot de passe"
                required
                value={password}
                onChange={onPasswordChanged}
              />
            </label>
          </div>
          <button
            disabled={loading}
            type="submit"
            className="bg-yellow-400 hover:bg-yellow-600 text-white uppercase text-sm font-semibold px-4 py-2 rounded-full"
          >
            {loading ? "Loading ..." : "Connexion"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
