import React, { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Table from "../Core/Table";
import { getFromApiFunction } from "../../api/apiFunctions";
import { Box, Tab, Tabs, Typography } from "@mui/material";

function customHeadRender(columnMeta: any, updateDirection: any) {
  return (
    <th
      className="text-start pl-4"
      key={columnMeta.label}
      onClick={() => updateDirection(2)}
      style={{ cursor: "pointer" }}
    >
      {columnMeta.label}
    </th>
  );
}

const TYPE_AMEN: any = {
  retenue: "En aval de retenue d'eau",
  entreprise: "Entreprise",
  himo: "HIMO",
  himo_entreprise: "HIMO - Entreprise",
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DataTableView() {
  // const [investissements, setInvestissements] = useState(null);
  const [invests, setInvests] = useState<any | null>([]);
  const [columns, setColumns] = useState<any | null>([]);
  const [magasins, setMagasins] = useState<any | null>([]);
  const [boutiques, setBoutiques] = useState<any | null>([]);

  const columnsBoutique = [];

  const [value, setValue] = React.useState(0);

  const tables = [
    {
      tabTitle: "Parties prenantes",
      endPoint: "/stakeholders/",
    },
  ];

  useEffect(() => {
    const table = tables[value];
    if (!table) return;
    setInvests([]);
    setColumns([]);
    getFromApiFunction(`${table.endPoint}verbose_names/`).then((response) => {
      const metadata = response.data;
      setColumns(
        metadata.map((column: any) => ({
          name: column.key,
          label: column.label,
          options: {
            filter:
              ["departement", "commune", "arrondissement"].indexOf(
                column.key.toLowerCase(),
              ) >= 0,
            customHeadRender,
          },
          // width: "150px"
        })),
      );
    });
  }, [value]);

  useEffect(() => {
    const table = tables[value];
    if (!table) return;
    getFromApiFunction(table.endPoint).then((response) => {
      const investissements = response.data;
      setInvests(
        investissements.map((investissement: any) =>
          columns.map((column: any) => {
            const value =
              column.name != "type_amen"
                ? investissement[column.name]
                : TYPE_AMEN[investissement.type_amen];
            return value;
          }),
        ),
      );
    });
  }, [columns]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let theme = createTheme({
    palette: {
      primary: {
        main: "#788c82",
      },
      secondary: {
        main: "#eb70d3",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="App p-5 h-screen">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            {tables.map((table, idx) => (
              <Tab key={idx} label={table.tabTitle} />
            ))}
          </Tabs>
        </Box>

        <Box className="md:overflow-auto p-0">
          {columns.length > 0 &&
            invests.length > 0 &&
            tables.map((table, idx) => (
              <TabPanel key={idx} value={value} index={idx}>
                <div className="grid grid-cols-1 md:grid-cols-1">
                  <Table tableData={invests} columns={columns} />
                </div>
              </TabPanel>
            ))}
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default DataTableView;
