interface DataToShow {
  siteName: string;
  category: string;
  phone: string;
  email: string;
  interventionFields: string;
  is_member: Boolean;
  longitude: number;
  latitude: number;
}
export interface MarkerConstructorInterface {
  localisation: any;

  image: string;

  label: DataToShow;
}

export default class MarkerModel {
  localisation: any;

  image: string;

  label: DataToShow;

  constructor({ localisation, image, label }: MarkerConstructorInterface) {
    this.localisation = localisation;
    this.image = image;
    this.label = label;
  }

  static fromJson(json: any) {
    return new MarkerModel({
      localisation: {
        longitude: json.longitude,
        latitude: json.latitude,
      },
      image: json.picture,
      label: {
        siteName: json.name,
        category: json.get_category_display,
        phone: json.phone_number,
        email: json.email,
        interventionFields: json.intervention_fields,
        is_member: json.is_member,
        latitude: json.latitude,
        longitude: json.longitude,
      },
    });
  }

  static fromJsonList(jsonList: any) {
    console.log(jsonList);
    return jsonList.map((json: any) => MarkerModel.fromJson(json));
  }
}
