import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          dashboard: {
            title: "Dashboard",
          },

          sidebar_titles: {
            home: "home page",
            mapping: "mapping",
            mapLibrary: "map library",
            report: "reports",
            contributor: "key contributors",
            dataTable: "data table",
            profile: "profile",
            settings: "settings",
            logout: "logout",
          },
          mapLibrary: {
            title: "map library",
          },
          reportPage: {
            title: "Reports",
          },
        },
      },
      fr: {
        translation: {
          dashboard: {
            title: "tableau de bord",
          },
          sidebar_titles: {
            home: "Accueil",
            mapping: "mapping",
            mapLibrary: "cartothèque",
            report: "rapports",
            contributor: "acteurs clés",
            dataTable: "tableau de données",
            profile: "profil",
            settings: "paramètres",
            logout: "Se déconnecter",
          },
          mapLibrary: {
            title: "cartothèque",
          },
          reportPage: {
            title: "Rapports",
          },
        },
      },
    },
  });

export default i18n;
