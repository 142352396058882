/* eslint-disable import/extensions */
import getAxiosConfig from "../axios";

export function apiLogin({ username, password }) {
  return getAxiosConfig().post("token/", {
    username,
    password,
  });
}

export const apiLogout = () => {
  localStorage.clear();
};
